import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import './notFoundPage.scss';
import notFoundImage from './notFoundImage.jpg';
import { PrimaryButton } from '@entur/button';
import { Paragraph, Heading1, Link } from '@entur/typography';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <div className="not-found__container">
    <img src={notFoundImage} alt="Not found image" />
    <Heading1 mdxType="Heading1">Dead End</Heading1>
    <Paragraph className="not-found__paragraph" mdxType="Paragraph">
        You may have mistyped the address or the page you are looking for have
        moved.
    </Paragraph>
    <Link href="/" mdxType="Link">
        <PrimaryButton mdxType="PrimaryButton">Main Page</PrimaryButton>
    </Link>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      